import Swiper from "swiper";
import { Pagination, Autoplay } from "swiper/modules";

export default function initSwiper() {
  const gallery = ".swiper-container";
  let bannerGallery;

  function initSwiperInstance() {
    if (
      !bannerGallery &&
      $(".swiper-container").hasClass("main-banner-group")
    ) {
      bannerGallery = new Swiper(gallery, {
        modules: [Pagination, Autoplay],
        loop: true,
        slidesPerView: 1,
        resizeObserver: true,
        autoplay: {
          delay: 2860,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
        breakpoints: {
          767: {
            slidesPerView: 2,
          },
        },
      });
    }
  }

  function destroySwiperInstance() {
    if (bannerGallery) {
      bannerGallery.destroy(true, true);
      bannerGallery = null;
    }
  }

  function handleResize() {
    const isMobile = window.innerWidth <= 1023;

    if (isMobile) {
      initSwiperInstance();
    } else {
      destroySwiperInstance();
    }
  }

  handleResize();
  window.addEventListener("resize", handleResize);
}
