import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default function instagramSlider() {
    new Swiper('.instagram-swiper', {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 2,
        spaceBetween: 15,
        speed: 200,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        observer: true,
        breakpoints: {
            480: {
                slidesPerView: 3,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 30
            },
            1224: {
                slidesPerView: 6,
                spaceBetween: 30
            }
        }
    });

    $.ajax({
        type: "GET",
        url: "/umbraco/surface/Instagram/GetInstagramMedias",
        success: function (data) {

            var slides = [];

            $.each(data, function (index, item) {
                var slide = `
                    <div class="swiper-slide">
                        <a href="${item.PostUrl}" target="_blank">
                            <div class="instagram-swiper-icon-holder"><img src="/Content/images/instagram-new.svg" class="instagram-icon" alt="instagram icon" width="30" height="30"></div>
                            <img loading="lazy" src="${item.ImageUrl}" class="instagram-swiper-image" alt="${item.Id}">
                        </a>
                    </div>`;
                slides.push(slide);
            });

            $('#instagram-medias').html(slides);
        },
        error: function (data) {
            $('#instagram').hide();
        }
    });
}